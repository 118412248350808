window.__tnt || (window.__tnt = {});
__tnt.metering || (__tnt.metering = {});

__tnt.metering.init = [];
__tnt.metering.access = {
    c:[], // conditionals
    d:[], // denied access
    e:[]  // entry granted
};

__tnt.metering.config = {
    date:0,         // date where the data store should reset
    days:0,         // number of days forward to set the next expiration date
    read:false,     // has the user already read this asset
    access:false,   // if the user has access to the content on this asset
    referred:false, // if the user was referred by social media
    login_after_wall:false, // if the user logged in after encountering the wall
    reg_limit:0,    // number of views before registration wall
    sub_limit:0,    // number of views before subscription wall
    uuid:''         // uuid of the current asset
};

__tnt.metering.check = function(){
    __tnt.log("evaluating metering");

    var access = __tnt.metering.access.d;
    var check = false;

    if(__tnt.metering.compatibility().check){
        __tnt.recent.set(__tnt.recent.get());
        __tnt.metering.config.uuid = $('#asset-content').attr('data-asset-uuid');

        if(__tnt.user.loggedIn && sessionStorage.getItem('__tnt.metering.wall.registration')){
            __tnt.metering.config.login_after_wall = true;
            try{
                sessionStorage.removeItem('__tnt.metering.wall.registration');
            }catch(e){};
        }

        $.each(__tnt.metering.init,function(){
            this();
        });

        var i = 0;
        while(!__tnt.metering.config.access && i < __tnt.metering.access.c.length){
            if(__tnt.metering.access.c[i]()){
                __tnt.log("access granted");
                access = __tnt.metering.access.e;
                __tnt.metering.config.access = true;
            }
            i++;
        }
    }

    $.each(access,function(){
        this();
    });

    __tnt.recent.set(__tnt.recent.data);

};

__tnt.metering.clean = function(){
    __tnt.metering.access = {
        c:[],
        d:[],
        e:[]
    };
    __tnt.metering.check = function(){ return false; };
};

/*
 * Compatibility Check
 * Runs checks to ensure browser compatibility.
 */
__tnt.metering.compatibility = function(){
    var compatible = true;
    var status = "";
    
    return {'check':compatible,'status':status};
};

__tnt.metering.gtm = function(bWall){
    var countRestriction = __tnt.metering.config.reg_limit;
    if(__tnt.user.loggedIn){ countRestriction = __tnt.metering.config.sub_limit; }
    
    var count = __tnt.recent.count.get();
    if(!__tnt.metering.config.read && __tnt.metering.config.access){ count = count+1; }
    
    var remaining = countRestriction - count;
    if(typeof(remaining)!="number" || remaining < 0){ remaining = 0; }
    
    var event = "tncms.event.trigger";
    var action = "free view";
    if(bWall && __tnt.user.loggedIn){
        action = "wall";
        try{
            if(window.sessionStorage){
                sessionStorage.setItem('__tnt.metering.wall.subscription', 1);
            }
        }catch(e){}
    }else if(bWall){
        action = "wall";
        try{
            if(window.sessionStorage){
                sessionStorage.setItem('__tnt.metering.wall.registration', 1);
            }
        }catch(e){}
    }
    
    if(__tnt.metering.statusPretty(bWall)=="logged in after encountering wall"){
        action = "goal";
    }
    
    if(window.dataLayer){
        dataLayer.push({
            'event':event, // event name
            'tncms':{
                'event':{
                    'trigger':{
                        'category':'subscription',
                        'action':action, // free view / wall
                        'label': __tnt.metering.statusPretty(bWall), // pretty status string
                        'value': count // count for averaging user views
                    }
                },
                'subscription':{
                    'meter':{
                        'views':{
                            'count': count, // assets viewed by user (counting up)
                            'rem_count': remaining // assets remaining before hitting current restriction (counting down)
                        },
                        'thresholds':{
                            'registration': __tnt.metering.config.reg_limit, // registration wall count
                            'subscription': __tnt.metering.config.sub_limit  // subscription wall count
                        }
                    }
                }
            }
        });
    }
};

/* 
 * Pretty Metering Status
 * Returns a nice string on the current state of the user in the metering process
 * metering_status : These are the possible values
     * Logged In After Wall
     * Hit Registration Limit
     * Hit Subscription Limit
     * Previously Read
     * Free Click
     * First View
     * From Social Media
     * Under Registration Limit
     * Under Subscription Limit
     * No Access
 */
__tnt.metering.statusPretty = function(bWall){
    if(__tnt.recent.count.get() === 0 && bWall){ return 'hit registration limit'; }
    if(__tnt.recent.count.get() === 0){ return 'first view'; }
    if(__tnt.user.loggedIn && __tnt.metering.config.login_after_wall){ return 'logged in after encountering wall'; }
    if(__tnt.metering.config.read){ return 'previously read'; }
    if(__tnt.metering.config.referred){ return 'free click'; }
    if(__tnt.user.loggedIn && bWall && __tnt.recent.count.get() == __tnt.metering.config.sub_limit){ return 'hit subscription limit'; }
    if(bWall && __tnt.recent.count.get() == __tnt.metering.config.sub_limit && __tnt.metering.sub_limit == __tnt.metering.config.reg_limit){ return 'hit subscription limit'; }
    if(bWall && (__tnt.recent.count.get() == __tnt.metering.config.reg_limit) && (__tnt.metering.config.reg_limit != __tnt.metering.config.sub_limit)){ return 'hit registration limit'; }
    if(!bWall && (__tnt.recent.count.get() < __tnt.metering.config.reg_limit) && (__tnt.metering.config.reg_limit != __tnt.metering.config.sub_limit)){ return 'under registration limit'; }
    if(!bWall && __tnt.recent.count.get() < __tnt.metering.config.sub_limit){ return 'under subscription limit'; }
    return 'hit subscription limit';
};

/*
 * Comment access
 * Runs checks to see if comments should be available to the user.
 */
__tnt.metering.comments = {
    access: function(container, notice) {
        if(__tnt.user.authToken == null || __tnt.user.services == null) {
            container.parentNode.removeChild(container);
        } else {
            notice.parentNode.removeChild(notice)
        }
    }
}

/*
 * Recent Asset Information
 * Stores count of recently viewed assets and short list of recently viewed assets
 */
// This could be moved into another file or even another component.
__tnt.recent || (__tnt.recent = {});

__tnt.recent = {
    data:{},
    
    get:function(){
        var date = new Date();
        var data = {c:0,d:date.getTime(),a:[]};
        var storedData = null;
        if(window.localStorage && localStorage.getItem('__tnt.recent')!=null){
            try{ storedData = localStorage.getItem('__tnt.recent');
            }catch(e){}

            if(storedData!=null&&storedData!=undefined&&storedData!='null'&&storedData!='undefined'){
                storedData = JSON.parse(localStorage.getItem('__tnt.recent'));
                
                if(storedData.c){
                    data.c = storedData.c;
                }
                if(storedData.d){
                    data.d = storedData.d;
                }
                if(storedData.a){
                    data.a = storedData.a;
                }
            }
        }

        __tnt.recent.data = data;
    return data; },
    
    set:function(data){
        var sData = JSON.stringify(__tnt.recent.data);
        try{
            localStorage.setItem('__tnt.recent', sData);
        }catch(e){
            console.log("encountered an error when setting local storage");
        };
    return sData; },

    count:{
        get:function(){
            count = Number(__tnt.recent.data.c);
        return count; },
        set:function(count){
            if(window.localStorage){
                __tnt.recent.data.c = count;
            };
        return count; }
    },

    assets:{
        get:function(){
            return __tnt.recent.data.a;
        },
        set:function(assets){
            __tnt.recent.data.a = assets;
        return __tnt.recent.data.a; },
        add:function(asset){
            var list = __tnt.recent.data.a;
            
            while(list.length>10){
                list.shift();
            }
            
            list.push(asset);
        }
    },
    
    date:{
        get:function(){
            return Number(__tnt.recent.data.d);
        },
        set:function(date){
            var time = new Date(date).getTime();
            
            __tnt.recent.data.d = time;
        return time; }  
    }
};

__tnt.referrer = function(sites,referer){
    cleanedReferrer = String(String(referer).split('?')[0]).replace(/https\:\/\/|http\:\/\//,'').replace(/www\./,'');

    if(sites.length){
        for(var s=0; s < sites.length; s++){
            var aSiteSubStr = sites[s].split('/'),
                aCRSubStr = cleanedReferrer.split('/'),
                c = true,
                i = 0;
        
            while(i < aSiteSubStr.length && c){
                if(aSiteSubStr[i] != aCRSubStr[i]){ c = false }
                i++
            }
            if(c){
                return true 
            }
        }
    }

    return false
};
